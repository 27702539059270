import { FC } from 'react';
import { TileResponse } from '../../../../../../types/strapi/tile/tile';
import './home-tile-card.scss';
import { NJCardCover } from '@engie-group/fluid-design-system-react';
import { getStrapiImage } from '../../../../../../utils/image';

interface HomeTileCardProps {
  tile: TileResponse;
}

const HomeTileCard: FC<HomeTileCardProps> = ({ tile }) => {
  return (
    <div className="htc-tile-container">
      <NJCardCover
        className="htc-tile ago-principal-title-bold"
        background={getStrapiImage(tile?.data?.attributes?.image?.data?.attributes)}
        heading={tile?.data?.attributes.title}
        href={tile?.data?.attributes.url}
        paragraph={tile?.data?.attributes.description ?? ''}
        target="_blank"
      />
    </div>
  );
};

export { HomeTileCard };
