import { isLocalhost } from '../serviceWorker';

export const getNonce = async (): Promise<string> => {
  if (isLocalhost || !process.env.REACT_APP_SALT) return '';

  const now = new Date();
  const dateStr = now.toISOString().slice(0, 16); // Format YYYY-MM-DDTHH:MM en UTC

  // Combiner le sel avec la date et l'heure
  const combined = `${process.env.SALT}${dateStr}`;

  // Convertir la chaîne en tableau d'octets
  const encoder = new TextEncoder();
  const data = encoder.encode(combined);

  // Générer le hachage
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
};
