import './nav-bar.scss';
import { FC } from 'react';
import { connect } from 'redux-bundler-react';
import Agora from '../../../../images/png/agora-logo.png';
import {
  NJNavbar,
  NJNavbarItem,
} from '@engie-group/fluid-design-system-react';
import { BurgerMenu } from '../burger-menu/burger-menu';
import { NavBarLinks, useAgoraNavBar } from './hooks/use-agora-nav-bar';

interface PropNavBar {
  userName: string;
  userId: string;
  doLogout: () => void;
  isAdmin: boolean;
}

const NavBar: FC<PropNavBar> = ({ doLogout, isAdmin, userName, userId }) => {
  const { activeItem, navBarItems, getFirstLetters } = useAgoraNavBar(isAdmin, userId, userName, doLogout)

  const divider = <div className="vertical-line-container hidding-mobile">
    <div className="vertical-line"></div>
  </div>

  const getItem = (item: NavBarLinks) => <NJNavbarItem {...{
    key: item.id,
    id: item.id,
    href: item.href
  }}>
    {item.name}
  </NJNavbarItem>

  const getItems = () => navBarItems.map((item) => item.id === "divider" ? divider : getItem(item))

  return (
    <header id="nj-navbar" className="app-header ago-header ago-navbar-resposive-overwrite">
      <NJNavbar
        {...{
          activeItemId: activeItem,
          breakpoint: "xl",
          logo: {
            href: '/',
            target: '_self',
            image: <img alt="logo" style={{ maxWidth: '8rem' }} src={Agora}></img>,
          },
          onClickItem: () => { },
          children: getItems().concat()
        }}
      />
      <BurgerMenu
        isAdmin={isAdmin}
        userId={userId}
        username={userName}
        onLogout={doLogout}
        getUserInitials={getFirstLetters}
      />
    </header >
  );
};

export default connect('selectUserName', 'selectUserId', 'doLogout', NavBar);
