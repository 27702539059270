import { useEffect, useState } from 'react';
import { EventAndTilesBlock } from '../../../../../../types/strapi/component/blocks/event-and-tiles';
import { EventReponse, EventType } from '../../../../../../types/events';
import { apiFetcher } from '../../../../../../services/api/apiFetcher';

const useHomeEventAndTilesBlock = (eventAndTilesBlock: EventAndTilesBlock) => {
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<EventType[]>([]);

  const fetchLatestsEvents = async () => {
    setIsEventsLoading(true);
    setEvents(
      (await apiFetcher<EventReponse>(`admin/events?fromToday=1&limit=${3}`)).data,
    );
    setIsEventsLoading(false);
  };

  const fetchEvents = async () => {
    setIsEventsLoading(true);
    const projects = await Promise.all(
      eventAndTilesBlock.event.map((eventComponent) =>
        apiFetcher<EventType>(`admin/events/${eventComponent.event_id}`),
      ),
    );
    setEvents(projects);
    setIsEventsLoading(false);
  };

  useEffect(() => {
    eventAndTilesBlock.event.length === 0 ? fetchLatestsEvents() : fetchEvents();
  }, [eventAndTilesBlock.event]);

  return { isEventsLoading, events };
};

export { useHomeEventAndTilesBlock };
