import { literal, number, object, string } from 'idonttrustlikethat';

const twoColumnComponent = literal('blocks.two-column');
const twoColumnBlock = object({
  id: number,
  __component: twoColumnComponent,
  title: string,
  first_column: string,
  second_column: string,
});

type TwoColumnBlock = typeof twoColumnBlock.T;
type TwoColumnComponent = typeof twoColumnComponent.T;

export { twoColumnBlock, twoColumnComponent };
export type { TwoColumnBlock, TwoColumnComponent };
