import { FC } from 'react';
import { DataFetcher } from '../../components/atomic/templates/data-fetcher';
import { referalApiWS } from '../../config/unsplash';
import StrapiRoutes from '../../services/strapi/strapiRoutes';
import { GroupDataPolicyDynamicZone } from '../../components/atomic/templates/group-data-policy/group-data-policy-dynamic-zone';
import { groupDataPolicy } from '../../types/strapi/group-data-policy/group-data-policy';

interface GroupDataPolicyProps {}

const GroupDataPolicy: FC<GroupDataPolicyProps> = () => {
  return (
    <>
      <DataFetcher
        {...{
          route: `${referalApiWS}/api/${StrapiRoutes.groupDataPolicy}`,
          params: {
            populate: ['blocks', 'blocks.background', 'blocks.backgroundSmall'],
          },
          validator: groupDataPolicy,
        }}
      >
        {(data) => (
          <GroupDataPolicyDynamicZone
            {...{
              blocks: data.data.attributes.blocks,
            }}
          />
        )}
      </DataFetcher>
    </>
  );
};

export default GroupDataPolicy;
