import { FC } from 'react';
import './group-data-policy-dynamic-zone.scss';
import { headerODTComponent } from '../../../../types/strapi/component/blocks/header-odt';
import { HeaderODTDisplayBlock } from '../../organisms/header-odt/blocks/header-odt-block';
import { GroupDataPolicyBlocks } from '../../../../types/strapi/component/blocks/group-data-policy-block';
import { twoColumnComponent } from '../../../../types/strapi/component/blocks/two-column-block';
import { TwoColumnDisplayBlock } from '../../organisms/two-column/blocks/two-column-block';

interface GroupDataPolicyDynamicZoneProps {
  blocks: GroupDataPolicyBlocks[];
}

const GroupDataPolicyDynamicZone: FC<GroupDataPolicyDynamicZoneProps> = ({ blocks }) => {
  return (
    <>
      {blocks.map((block: GroupDataPolicyBlocks) => {
        switch (block.__component) {
          case headerODTComponent.literal:
            return (
              <HeaderODTDisplayBlock
                headerODTBlock={block}
                pathList={[{ url: '/onedatateam', label: 'One Data Team' }]}
              />
            );
          case twoColumnComponent.literal:
            return <TwoColumnDisplayBlock twoColumnBlock={block} />;
          default:
            return <p>{block}</p>;
        }
      })}
    </>
  );
};

export { GroupDataPolicyDynamicZone };
