import { FC } from 'react';
import './header-odt-block.scss';
import { getStrapiImage, IMG_FORMATS } from '../../../../../utils/image';
import { HeaderODTBlock } from '../../../../../types/strapi/component/blocks/header-odt';
import Breadcrumb from '../../../../../modules/Breadcrumb/Breadcrumb';

interface HeaderODTBlockBlockProps {
  headerODTBlock: HeaderODTBlock;
  pathList: {url: string, label: string}[];
}

const HeaderODTDisplayBlock: FC<HeaderODTBlockBlockProps> = ({
  headerODTBlock,
  pathList,
}) => {
  const path = [...pathList, { url: '', label: headerODTBlock.title }]
  return (
    <div
      className="hob-background hob-layout"
      style={
        {
          '--header-odt-container-background': `url(${getStrapiImage(
            headerODTBlock?.background?.data?.attributes,
            IMG_FORMATS.full
          )})`,
          '--header-odt-small-container-background': `url(${getStrapiImage(
            headerODTBlock?.backgroundSmall?.data?.attributes ?? '',
            IMG_FORMATS.full
          )})`,
        } as React.CSSProperties
      }
    >
      <Breadcrumb path={path} />
      <div>
        <h1 className="ago-tera-title">{headerODTBlock.title}</h1>
        <h2 className="hob-subtitle ago-subtitle-h3-bold">{headerODTBlock.subtitle}</h2>
      </div>
      <div className="hob-end" />
    </div>
  );
};

export { HeaderODTDisplayBlock };
