import { array, number, object, intersection } from 'idonttrustlikethat';
import { strapiCommonDates } from '../common/common-types';
import { groupDataPolicyBlocks } from '../component/blocks/group-data-policy-block';

const groupDataPolicy = object({
  data: object({
    id: number,
    attributes: intersection(
      strapiCommonDates,
      object({
        blocks: array(groupDataPolicyBlocks),
      }),
    ),
  }),
});

type GroupDataPolicy = typeof groupDataPolicy.T;

export { groupDataPolicy };
export type { GroupDataPolicy };
