import { literal, number, object, string } from "idonttrustlikethat";
import { strapiFileResponse } from "../../file/strapi-file";

const headerODTComponent = literal("blocks.header-odt");
const headerODTBlock = object({
  id: number,
  __component: headerODTComponent,
  title: string,
  subtitle: string,
  background: strapiFileResponse,
  backgroundSmall: strapiFileResponse
});

type HeaderODTBlock = typeof headerODTBlock.T;
type HeaderODTComponent = typeof headerODTComponent.T;

export { headerODTBlock, headerODTComponent };
export type { HeaderODTBlock, HeaderODTComponent };
