import { referalApiWS } from "../../../../../../config/unsplash";
import { getToken } from "../../../../../../services/authService";
import StrapiRoutes from "../../../../../../services/strapi/strapiRoutes";
import { ucEcosystemResponseEdit } from "../../../../../../types/strapi/component/ecosystem/uc-ecosystem-response";
import { vfetch } from "../../../../../../utils/fetch";


export const submitEditedEcosystem = async (id: number) => {
  const { accessToken } = await getToken('accessToken');
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  await vfetch(
    `${referalApiWS}/api/${StrapiRoutes.ecosystem}/usecase_ecosystem/${id}`,
    { ok: ucEcosystemResponseEdit },
    requestOptions,
  );
};