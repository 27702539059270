import { union } from 'idonttrustlikethat';
import { headerODTBlock, headerODTComponent } from './header-odt';
import { twoColumnBlock, twoColumnComponent } from './two-column-block';

const groupDataPolicyBlocksComponents = union(headerODTComponent, twoColumnComponent);
const groupDataPolicyBlocks = union(headerODTBlock, twoColumnBlock);

type GroupDataPolicyBlocks = typeof groupDataPolicyBlocks.T;
type GroupDataPolicyComponents = typeof groupDataPolicyBlocksComponents.T;

export { groupDataPolicyBlocks, groupDataPolicyBlocksComponents };
export type { GroupDataPolicyBlocks, GroupDataPolicyComponents };
